import React, {useEffect} from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    window.UC_UI_SUPPRESS_CMP_DISPLAY = true;

    return (
        <>
            <SEO title="Impressum"/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BreadCrumbOne
                    title="Impressum"
                    page="Impressum"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <h4>Angaben gem&auml;&szlig; &sect; 5 TMG</h4>
                                    <p>
                                        Goran Antunovic<br/>
                                        Inh. Goran Antunovic<br/>
                                        Regulus Solutions<br/>
                                        Bruckenäcker 6<br/>
                                        72127 Kusterdingen
                                    </p>

                                    <h4>Kontakt</h4>
                                    <p>
                                        Telefon: +49 7071 639 8294<br/>
                                        E-Mail: info@regulus-solutions.de
                                    </p>

                                    <h4>Redaktionell verantwortlich</h4>
                                    <p>Goran Antunovic</p>

                                    <h4>EU-Streitschlichtung</h4>
                                    <p>
                                        Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung
                                        (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank"
                                                        rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br/> Unsere
                                        E-Mail-Adresse finden Sie oben im Impressum.
                                    </p>

                                    <h4>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h4>
                                    <p>
                                        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                                        Verbraucherschlichtungsstelle teilzunehmen.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default TermsOfUse;
