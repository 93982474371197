import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutThree from '../component/about/AboutThree';
import Cta from "../component/cta/Cta";
import WhyChooseOne from "../component/whychoose/WhyChooseOne";


const AboutUs = () => {

    return (
        <>
            <SEO title="Über uns"/>
            <main className="main-wrapper">
                <HeaderOne/>
                {/*<BcrumbBannerOne*/}
                {/*    title="Wer sind wir?"*/}
                {/*    paragraph="Wir sind ein Team leidenschaftlicher und erfahrener Softwareingenieure und Produktdesigner, die sich darauf spezialisiert haben, großartige Software zu entwickeln. Unsere Mission besteht darin, Unternehmen dabei zu helfen, Erfolg zu haben, indem wir ihnen IT-Lösungen bereitstellen, die ihren individuellen Bedürfnissen und Anforderungen entsprechen."*/}
                {/*    styleClass="thumbnail-3"*/}
                {/*/>*/}
                <WhyChooseOne/>
                <AboutThree/>
                <Cta/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default AboutUs;
