import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    window.UC_UI_SUPPRESS_CMP_DISPLAY = true;

    return (
        <>
            <SEO title="Datenschutz"/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BreadCrumbOne
                    title="Datenschutz"
                    page="Datenschutz"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">Diese Datenschutzerklärung wurde am 10. Juli 2023
                                            veröffentlicht.</h5>
                                    </div>

                                    <h2>Datenschutz&shy;erkl&auml;rung</h2>
                                    <h3>1. Datenschutz auf einen Blick</h3>
                                    <h4>Allgemeine Hinweise</h4> <p>Die folgenden Hinweise geben einen
                                    einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten
                                    passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten,
                                    mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche
                                    Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
                                    aufgef&uuml;hrten Datenschutzerkl&auml;rung.</p>
                                    <h4>Datenerfassung auf dieser Website</h4> <h5>Wer ist verantwortlich f&uuml;r die
                                    Datenerfassung auf dieser Website?</h5> <p>Die Datenverarbeitung auf dieser Website
                                    erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem
                                    Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
                                    Datenschutzerkl&auml;rung entnehmen.</p> <h5>Wie erfassen wir Ihre Daten?</h5>
                                    <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
                                        Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular
                                        eingeben.</p> <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung
                                    beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
                                    technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des
                                    Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
                                    Website betreten.</p> <h5>Wof&uuml;r nutzen wir Ihre Daten?</h5> <p>Ein Teil der
                                    Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
                                    gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens
                                    verwendet werden.</p> <h5>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h5>
                                    <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft,
                                        Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
                                        erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder
                                        L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
                                        Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit
                                        f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter
                                        bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
                                        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
                                        Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.</p> <p>Hierzu
                                    sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns
                                    wenden.</p>
                                    <h4>Analyse-Tools und Tools von Dritt&shy;anbietern</h4> <p>Beim Besuch dieser
                                    Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
                                    allem mit sogenannten Analyseprogrammen.</p> <p>Detaillierte Informationen zu diesen
                                    Analyseprogrammen finden Sie in der folgenden Datenschutzerkl&auml;rung.</p>
                                    <h3>2. Hosting und Content Delivery Networks (CDN)</h3>
                                    <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
                                    <h4>Amazon Web Services (AWS)</h4> <p>Anbieter ist die Amazon Web Services EMEA
                                    SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg (nachfolgend AWS).</p> <p>Wenn Sie
                                    unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS
                                    verarbeitet. Hierbei k&ouml;nnen auch personenbezogene Daten an das
                                    Mutterunternehmen von AWS in die USA &uuml;bermittelt werden. Die
                                    Daten&uuml;bertragung in die USA wird auf die EU-Standardvertragsklauseln
                                    gest&uuml;tzt. Details finden Sie hier: <a
                                        href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
                                        target="_blank"
                                        rel="noopener noreferrer">https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/</a>.
                                </p> <p>Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von AWS: <a
                                    href="https://aws.amazon.com/de/privacy/?nc1=f_pr" target="_blank"
                                    rel="noopener noreferrer">https://aws.amazon.com/de/privacy/?nc1=f_pr</a>.</p>
                                    <p>Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir
                                        haben ein berechtigtes Interesse an einer m&ouml;glichst zuverl&auml;ssigen
                                        Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt
                                        wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6
                                        Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die
                                        Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des
                                        Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                                        Einwilligung ist jederzeit widerrufbar.</p>
                                    <h5>Auftragsverarbeitung</h5> <p>Wir haben einen Vertrag &uuml;ber
                                    Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen.
                                    Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
                                    gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher
                                    nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</p>
                                    <h4>Cloudflare</h4> <p>Wir nutzen den Service &bdquo;Cloudflare&ldquo;. Anbieter ist
                                    die Cloudflare Inc., 101 Townsend St., San Francisco, CA 94107, USA (im
                                    Folgenden &bdquo;Cloudflare&rdquo;).</p> <p>Cloudflare bietet ein weltweit
                                    verteiltes Content Delivery Network mit DNS an. Dabei wird technisch der
                                    Informationstransfer zwischen Ihrem Browser und unserer Website &uuml;ber das
                                    Netzwerk von Cloudflare geleitet. Das versetzt Cloudflare in die Lage, den
                                    Datenverkehr zwischen Ihrem Browser und unserer Website zu analysieren und als
                                    Filter zwischen unseren Servern und potenziell b&ouml;sartigem Datenverkehr aus dem
                                    Internet zu dienen. Hierbei kann Cloudflare auch Cookies oder sonstige Technologien
                                    zur Wiedererkennung von Internetnutzern einsetzen, die jedoch allein zum hier
                                    beschriebenen Zweck verwendet werden.</p> <p>Der Einsatz von Cloudflare beruht auf
                                    unserem berechtigten Interesse an einer m&ouml;glichst fehlerfreien und sicheren
                                    Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).</p> <p>Die
                                    Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der
                                    EU-Kommission gest&uuml;tzt. Details finden Sie hier: <a
                                        href="https://www.cloudflare.com/privacypolicy/" target="_blank"
                                        rel="noopener noreferrer">https://www.cloudflare.com/privacypolicy/</a>.</p>
                                    <p>Weitere Informationen zum Thema Sicherheit und Datenschutz bei Cloudflare finden
                                        Sie hier: <a href="https://www.cloudflare.com/privacypolicy/" target="_blank"
                                                     rel="noopener noreferrer">https://www.cloudflare.com/privacypolicy/</a>.
                                    </p>
                                    <h5>Auftragsverarbeitung</h5> <p>Wir haben einen Vertrag &uuml;ber
                                    Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen.
                                    Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
                                    gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher
                                    nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</p>
                                    <h4>Amazon CloudFront CDN</h4> <p>Wir nutzen das Content Delivery Network Amazon
                                    CloudFront CDN. Anbieter ist die Amazon Web Services EMEA SARL, 38 avenue John F.
                                    Kennedy, L-1855, Luxemburg (nachfolgend &bdquo;Amazon&ldquo;).</p> <p>Bei Amazon
                                    CloudFront CDN handelt es sich um ein weltweit verteiltes Content Delivery Network.
                                    Dabei wird technisch der Informationstransfer zwischen Ihrem Browser und unserer
                                    Website &uuml;ber das Content Delivery Network geleitet. Hierdurch k&ouml;nnen wir
                                    die weltweite Erreichbarkeit und die Leistungsf&auml;higkeit unserer Website
                                    erh&ouml;hen.</p> <p>Der Einsatz von Amazon CloudFront CDN beruht auf unserem
                                    berechtigten Interesse an einer m&ouml;glichst fehlerfreien und sicheren
                                    Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).</p> <p>Die
                                    Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der
                                    EU-Kommission gest&uuml;tzt. Details finden Sie hier: <a
                                        href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
                                        target="blank"
                                        rel="noopener noreferrer">https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/</a>.
                                </p> <p>Weitere Informationen zu Amazon CloudFront CDN finden Sie hier: <a
                                    href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_NoticeGerman_Translation.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer">https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_German_Translation.pdf</a>.
                                </p>
                                    <h5>Auftragsverarbeitung</h5> <p>Wir haben einen Vertrag &uuml;ber
                                    Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen.
                                    Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
                                    gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher
                                    nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</p>
                                    <h3>3. Allgemeine Hinweise und Pflicht&shy;informationen</h3>
                                    <h4>Datenschutz</h4> <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                                    pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                                    vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
                                    Datenschutzerkl&auml;rung.</p> <p>Wenn Sie diese Website benutzen, werden
                                    verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit
                                    denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
                                    Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und wof&uuml;r
                                    wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.</p>
                                    <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei
                                        der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein
                                        l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                                        m&ouml;glich.</p>
                                    <h4>Hinweis zur verantwortlichen Stelle</h4> <p>Die verantwortliche Stelle f&uuml;r
                                    die Datenverarbeitung auf dieser Website ist:</p>
                                    <p>
                                        Goran Antunovic<br/>
                                        Inh. Goran Antunovic<br/>
                                        Regulus Solutions<br/>
                                        Bruckenäcker 6<br/>
                                        72127 Kusterdingen
                                    </p>
                                    <p>Telefon: +49 7071 639 8294<br/>
                                        E-Mail: info@regulus-solutions.de</p>
                                    <p>Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die
                                        allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der
                                        Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen
                                        o. &Auml;.) entscheidet.</p>

                                    <h4>Speicherdauer</h4> <p>Soweit innerhalb dieser Datenschutzerkl&auml;rung keine
                                    speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
                                    uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn Sie ein
                                    berechtigtes L&ouml;schersuchen geltend machen oder eine Einwilligung zur
                                    Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht, sofern wir keine
                                    anderen rechtlich zul&auml;ssigen Gr&uuml;nde f&uuml;r die Speicherung Ihrer
                                    personenbezogenen Daten haben (z.&nbsp;B. steuer- oder handelsrechtliche
                                    Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L&ouml;schung nach
                                    Fortfall dieser Gr&uuml;nde.</p>
                                    <h4>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser
                                        Website</h4> <p>Sofern Sie in die Datenverarbeitung eingewilligt haben,
                                    verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a
                                    DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9
                                    Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdr&uuml;cklichen Einwilligung in
                                    die &Uuml;bertragung personenbezogener Daten in Drittstaaten erfolgt die
                                    Datenverarbeitung au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO.
                                    Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
                                    Ihr Endger&auml;t (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt
                                    die Datenverarbeitung zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1 TTDSG. Die
                                    Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserf&uuml;llung
                                    oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich,
                                    verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
                                    Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erf&uuml;llung einer
                                    rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c
                                    DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
                                    Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die jeweils im
                                    Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den folgenden Abs&auml;tzen
                                    dieser Datenschutzerkl&auml;rung informiert.</p>
                                    <h4>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h4> <p>Wir
                                    verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen
                                    datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind,
                                    k&ouml;nnen Ihre personenbezogene Daten in diese Drittstaaten &uuml;bertragen und
                                    dort verarbeitet werden. Wir weisen darauf hin, dass in diesen L&auml;ndern kein mit
                                    der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind
                                    US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden
                                    herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen
                                    k&ouml;nnten. Es kann daher nicht ausgeschlossen werden, dass US-Beh&ouml;rden
                                    (z.&nbsp;B. Geheimdienste) Ihre auf US-Servern befindlichen Daten
                                    zu &Uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir
                                    haben auf diese Verarbeitungst&auml;tigkeiten keinen Einfluss.</p><h4>Empfänger von
                                    personenbezogenen Daten</h4> <p>Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit
                                    arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei ist teilweise auch
                                    eine &Uuml;bermittlung von personenbezogenen Daten an diese externen Stellen
                                    erforderlich. Wir geben personenbezogene Daten nur dann an externe Stellen weiter,
                                    wenn dies im Rahmen einer Vertragserf&uuml;llung erforderlich ist, wenn wir
                                    gesetzlich hierzu verpflichtet sind (z.&nbsp;B. Weitergabe von Daten an
                                    Steuerbeh&ouml;rden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f
                                    DSGVO an der Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die
                                    Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern geben wir
                                    personenbezogene Daten unserer Kunden nur auf Grundlage eines g&uuml;ltigen
                                    Vertrags &uuml;ber Auftragsverarbeitung weiter. Im Falle einer gemeinsamen
                                    Verarbeitung wird ein Vertrag &uuml;ber gemeinsame Verarbeitung geschlossen.</p>
                                    <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4> <p>Viele
                                    Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung
                                    m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung jederzeit
                                    widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
                                    Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.</p>
                                    <h4>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen
                                        Direktwerbung (Art. 21 DSGVO)</h4> <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE
                                    VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
                                    GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
                                    VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
                                    F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE
                                    RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
                                    DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE
                                    BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR
                                    K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG
                                    NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE
                                    VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON
                                    RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p> <p>WERDEN IHRE
                                    PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS
                                    RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
                                    PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH
                                    F&Uuml;R DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT.
                                    WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR
                                    ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
                                    <h4>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</h4>
                                    <p>Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein
                                        Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem
                                        Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des
                                        Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht
                                        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
                                        Rechtsbehelfe.</p>
                                    <h4>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h4> <p>Sie haben das Recht,
                                    Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines
                                    Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem
                                    g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die
                                    direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen,
                                    erfolgt dies nur, soweit es technisch machbar ist.</p>
                                    <h4>Auskunft, Berichtigung und L&ouml;schung</h4> <p>Sie haben im Rahmen der
                                    geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                                    Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
                                    Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
                                    Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum
                                    Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
                                    <h4>Recht auf Einschr&auml;nkung der Verarbeitung</h4> <p>Sie haben das Recht, die
                                    Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                    Hierzu k&ouml;nnen Sie sich jederzeit an uns wenden. Das Recht auf
                                    Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:</p>
                                    <ul>
                                        <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
                                            bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies
                                            zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das
                                            Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten
                                            zu verlangen.
                                        </li>
                                        <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig
                                            geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung die
                                            Einschr&auml;nkung der Datenverarbeitung verlangen.
                                        </li>
                                        <li>Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie
                                            jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
                                            Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
                                            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
                                            personenbezogenen Daten zu verlangen.
                                        </li>
                                        <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss
                                            eine Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen werden.
                                            Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie
                                            das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                                            Daten zu verlangen.
                                        </li>
                                    </ul>
                                    <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben,
                                        d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit
                                        Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
                                        Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen
                                        oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen
                                        Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet
                                        werden.</p>
                                    <h4>SSL- bzw. TLS-Verschl&uuml;sselung</h4> <p>Diese Seite nutzt aus
                                    Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte,
                                    wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
                                    senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
                                    erkennen Sie daran, dass die Adresszeile des Browsers
                                    von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
                                    Schloss-Symbol in Ihrer Browserzeile.</p> <p>Wenn die SSL- bzw.
                                    TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an
                                    uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>
                                    <h4>Widerspruch gegen Werbe-E-Mails</h4> <p>Der Nutzung von im Rahmen der
                                    Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht
                                    ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit
                                    widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche
                                    Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
                                    Spam-E-Mails, vor.</p>
                                    <h3>4. Datenerfassung auf dieser Website</h3>
                                    <h4>Einwilligung mit Usercentrics</h4> <p>Diese Website nutzt die
                                    Consent-Technologie von Usercentrics, um Ihre Einwilligung zur Speicherung
                                    bestimmter Cookies auf Ihrem Endger&auml;t oder zum Einsatz bestimmter Technologien
                                    einzuholen und diese datenschutzkonform zu dokumentieren. Anbieter dieser
                                    Technologie ist die Usercentrics GmbH, Sendlinger Stra&szlig;e 7, 80331
                                    M&uuml;nchen, Website: <a href="https://usercentrics.com/de/" target="_blank"
                                                              rel="noopener noreferrer">https://usercentrics.com/de/</a> (im
                                    Folgenden &bdquo;Usercentrics&ldquo;).</p> <p>Wenn Sie unsere Website betreten,
                                    werden folgende personenbezogene Daten an Usercentrics &uuml;bertragen:</p>
                                    <ul>
                                        <li>Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)</li>
                                        <li>Ihre IP-Adresse</li>
                                        <li>Informationen &uuml;ber Ihren Browser</li>
                                        <li>Informationen &uuml;ber Ihr Endger&auml;t</li>
                                        <li>Zeitpunkt Ihres Besuchs auf der Website</li>
                                    </ul>
                                    <p>Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser, um Ihnen die
                                        erteilten Einwilligungen bzw. deren Widerruf zuordnen zu k&ouml;nnen. Die so
                                        erfassten Daten werden gespeichert, bis Sie uns zur L&ouml;schung auffordern,
                                        das Usercentrics-Cookie selbst l&ouml;schen oder der Zweck f&uuml;r die
                                        Datenspeicherung entf&auml;llt. Zwingende gesetzliche Aufbewahrungspflichten
                                        bleiben unber&uuml;hrt.</p> <p>Das Usercentrics-Banner auf dieser Website wurde
                                    mit Hilfe von eRecht24 konfiguriert. Das erkennen Sie daran, dass im Banner das Logo
                                    von eRecht24 auftaucht. Um das eRecht24-Logo im Banner auszuspielen, wird eine
                                    Verbindung zum Bildserver von eRecht24 hergestellt. Hierbei wird auch die
                                    IP-Adresse &uuml;bertragen, die jedoch nur in anonymisierter Form in den Server-Logs
                                    gespeichert wird. Der Bildserver von eRecht24 befindet sich in Deutschland bei einem
                                    deutschen Anbieter. Das Banner selbst wird ausschlie&szlig;lich von Usercentrics zur
                                    Verf&uuml;gung gestellt.</p>
                                    <p>Der Einsatz von Usercentrics erfolgt, um die gesetzlich vorgeschriebenen
                                        Einwilligungen f&uuml;r den Einsatz bestimmter Technologien einzuholen.
                                        Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. c DSGVO.</p>
                                    <h5>Auftragsverarbeitung</h5> <p>Wir haben einen Vertrag &uuml;ber
                                    Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen.
                                    Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
                                    gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher
                                    nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</p>
                                    <h4>Einwilligung mit Cookie Notice &amp; Compliance</h4> <p>Unsere Website nutzt die
                                    Consent-Technologie von Cookie Notice &amp; Compliance for GDPR, um Ihre
                                    Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endger&auml;t oder zum
                                    Einsatz bestimmter Technologien einzuholen und diese datenschutzkonform zu
                                    dokumentieren.</p> <p>Cookie Notice &amp; Compliance for GDPR ist lokal auf unseren
                                    Servern installiert, sodass keine Verbindung zu Servern von Dritten hergestellt
                                    wird. Cookie Notice &amp; Compliance for GDPR speichert einen Cookie in Ihrem
                                    Browser, um Ihnen die erteilten Einwilligungen bzw. deren Widerruf zuordnen zu
                                    k&ouml;nnen. Das Cookie bleibt 1 Monat lang aktiv. Im &Uuml;brigen werden Ihre Daten
                                    gespeichert, bis Sie uns zur L&ouml;schung auffordern, das Consent-Cookie
                                    selbstst&auml;ndig l&ouml;schen oder der Zweck f&uuml;r die Datenspeicherung
                                    entf&auml;llt. Zwingende gesetzliche Aufbewahrungspflichten bleiben
                                    unber&uuml;hrt.</p>
                                    <p>Der Einsatz von Cookie Notice &amp; Compliance for GDPR erfolgt, um die
                                        gesetzlich vorgeschriebenen Einwilligungen f&uuml;r den Einsatz von Cookies
                                        einzuholen. Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. c DSGVO.</p>
                                    <h4>Einwilligung mit Complianz</h4> <p>Unsere Website nutzt die Consent-Technologie
                                    von Complianz, um Ihre Einwilligung zur Speicherung bestimmter Cookies auf Ihrem
                                    Endger&auml;t oder zum Einsatz bestimmter Technologien einzuholen und diese
                                    datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist Complianz B.V.,
                                    Kalmarweg 14-5, 9723 JG Groningen, Niederlande (im
                                    Folgenden &bdquo;Complianz&ldquo;).</p> <p>Complianz wird auf unseren Servern
                                    gehostet, sodass keine Verbindung zu den Servern des Anbieters von Complianz
                                    hergestellt wird. Complianz speichert einen Cookie in Ihrem Browser, um Ihnen die
                                    erteilten Einwilligungen bzw. deren Widerruf zuordnen zu k&ouml;nnen. Die so
                                    erfassten Daten werden gespeichert, bis Sie uns zur L&ouml;schung auffordern, den
                                    Complianz-Cookie selbst l&ouml;schen oder der Zweck f&uuml;r die Datenspeicherung
                                    entf&auml;llt. Zwingende gesetzliche Aufbewahrungspflichten bleiben
                                    unber&uuml;hrt.</p> <p>Der Einsatz von Complianz erfolgt, um die gesetzlich
                                    vorgeschriebenen Einwilligungen f&uuml;r den Einsatz von Cookies einzuholen.
                                    Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. c DSGVO.</p>
                                    <h4>Server-Log-Dateien</h4> <p>Der Provider der Seiten erhebt und speichert
                                    automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser
                                    automatisch an uns &uuml;bermittelt. Dies sind:</p>
                                    <ul>
                                        <li>Browsertyp und Browserversion</li>
                                        <li>verwendetes Betriebssystem</li>
                                        <li>Referrer URL</li>
                                        <li>Hostname des zugreifenden Rechners</li>
                                        <li>Uhrzeit der Serveranfrage</li>
                                        <li>IP-Adresse</li>
                                    </ul>
                                    <p>Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht
                                        vorgenommen.</p> <p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6
                                    Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
                                    technisch fehlerfreien Darstellung und der Optimierung seiner Website &ndash; hierzu
                                    m&uuml;ssen die Server-Log-Files erfasst werden.</p>
                                    <h4>Kontaktformular</h4> <p>Wenn Sie uns per Kontaktformular Anfragen zukommen
                                    lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
                                    angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von
                                    Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
                                    Einwilligung weiter.</p> <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von
                                    Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
                                    Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher
                                    Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die
                                    Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an
                                    uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung
                                    (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist
                                    jederzeit widerrufbar.</p> <p>Die von Ihnen im Kontaktformular eingegebenen Daten
                                    verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
                                    Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt
                                    (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
                                    Bestimmungen &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben
                                    unber&uuml;hrt.</p>
                                    <h4>Anfrage per E-Mail, Telefon oder Telefax</h4> <p>Wenn Sie uns per E-Mail,
                                    Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
                                    hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
                                    Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht
                                    ohne Ihre Einwilligung weiter.</p> <p>Die Verarbeitung dieser Daten erfolgt auf
                                    Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
                                    eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher
                                    Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die
                                    Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an
                                    uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung
                                    (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist
                                    jederzeit widerrufbar.</p> <p>Die von Ihnen an uns per
                                    Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur
                                    L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der
                                    Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
                                    Bearbeitung Ihres Anliegens). Zwingende gesetzliche
                                    Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
                                    unber&uuml;hrt.</p>
                                    <h3>5. Soziale Medien</h3>
                                    <h4>eRecht24 Safe Sharing Tool</h4> <p>Die Inhalte auf dieser Website k&ouml;nnen
                                    datenschutzkonform in sozialen Netzwerken wie Facebook, Twitter &amp; Co. geteilt
                                    werden. Diese Seite nutzt daf&uuml;r das <a
                                        href="https://www.e-recht24.de/erecht24-safe-sharing.html" target="_blank"
                                        rel="noopener noreferrer">eRecht24 Safe Sharing Tool</a>. Dieses Tool stellt den
                                    direkten Kontakt zwischen den Netzwerken und Nutzern erst dann her, wenn der Nutzer
                                    aktiv auf einen dieser Button klickt. Der Klick auf den Button stellt eine
                                    Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG dar.
                                    Diese Einwilligung kann jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen
                                    werden.</p> <p>Eine automatische &Uuml;bertragung von Nutzerdaten an die Betreiber
                                    dieser Plattformen erfolgt durch dieses Tool nicht. Ist der Nutzer bei einem der
                                    sozialen Netzwerke angemeldet, erscheint bei der Nutzung der Social-Media-Elemente
                                    von Facebook, Twitter &amp; Co. ein Informations-Fenster, in dem der Nutzer den Text
                                    vor dem Absenden best&auml;tigen kann.</p> <p>Unsere Nutzer k&ouml;nnen die Inhalte
                                    dieser Seite datenschutzkonform in sozialen Netzwerken teilen, ohne dass komplette
                                    Surf-Profile durch die Betreiber der Netzwerke erstellt werden.</p> <p>Der Einsatz
                                    des Dienstes erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen f&uuml;r den
                                    Einsatz bestimmter Technologien einzuholen. Rechtsgrundlage hierf&uuml;r ist Art. 6
                                    Abs. 1 lit. c DSGVO.</p>
                                    <h4>Facebook</h4> <p>Auf dieser Website sind Elemente des sozialen Netzwerks
                                    Facebook integriert. Anbieter dieses Dienstes ist die Meta Platforms Ireland
                                    Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach
                                    Aussage von Facebook jedoch auch in die USA und in andere
                                    Drittl&auml;nder &uuml;bertragen.</p> <p>Eine &Uuml;bersicht &uuml;ber die Facebook
                                    Social-Media-Elemente finden Sie hier: <a
                                        href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
                                        target="_blank"
                                        rel="noopener noreferrer">https://developers.facebook.com/docs/plugins/?locale=de_DE</a>.
                                </p> <p>Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen
                                    Ihrem Endger&auml;t und dem Facebook-Server hergestellt. Facebook erh&auml;lt
                                    dadurch die Information, dass Sie mit Ihrer IP-Adresse diese Website besucht haben.
                                    Wenn Sie den Facebook &bdquo;Like-Button&ldquo; anklicken, w&auml;hrend Sie in Ihrem
                                    Facebook-Account eingeloggt sind, k&ouml;nnen Sie die Inhalte dieser Website auf
                                    Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser Website
                                    Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der
                                    Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren Nutzung
                                    durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der
                                    Datenschutzerkl&auml;rung von Facebook unter: <a
                                        href="https://de-de.facebook.com/privacy/explanation" target="_blank"
                                        rel="noopener noreferrer">https://de-de.facebook.com/privacy/explanation</a>.
                                </p> <p>Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des
                                    o.&nbsp;g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25
                                    TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung
                                    eingeholt wurde, erfolgt die Verwendung des Dienstes auf Grundlage unseres
                                    berechtigten Interesses an einer m&ouml;glichst umfassenden Sichtbarkeit in den
                                    Sozialen Medien.</p> <p>Soweit mit Hilfe des hier beschriebenen Tools
                                    personenbezogene Daten auf unserer Website erfasst und an Facebook weitergeleitet
                                    werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand
                                    Canal Harbour, Dublin 2, Irland gemeinsam f&uuml;r diese Datenverarbeitung
                                    verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschr&auml;nkt
                                    sich dabei ausschlie&szlig;lich auf die Erfassung der Daten und deren Weitergabe an
                                    Facebook. Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook ist
                                    nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
                                    Verpflichtungen wurden in einer Vereinbarung &uuml;ber gemeinsame Verarbeitung
                                    festgehalten. Den Wortlaut der Vereinbarung finden Sie unter: <a
                                        href="https://www.facebook.com/legal/controller_addendum" target="_blank"
                                        rel="noopener noreferrer">https://www.facebook.com/legal/controller_addendum</a>.
                                    Laut dieser Vereinbarung sind wir f&uuml;r die Erteilung der
                                    Datenschutzinformationen beim Einsatz des Facebook-Tools und f&uuml;r die
                                    datenschutzrechtlich sichere Implementierung des Tools auf unserer Website
                                    verantwortlich. F&uuml;r die Datensicherheit der Facebook-Produkte ist Facebook
                                    verantwortlich. Betroffenenrechte (z.&nbsp;B. Auskunftsersuchen) hinsichtlich der
                                    bei Facebook verarbeiteten Daten k&ouml;nnen Sie direkt bei Facebook geltend machen.
                                    Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese
                                    an Facebook weiterzuleiten.</p> <p>Die Daten&uuml;bertragung in die USA wird auf die
                                    Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie
                                    hier: <a href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                                             target="_blank"
                                             rel="noopener noreferrer">https://www.facebook.com/legal/EU_data_transfer_addendum</a>, <a
                                        href="https://de-de.facebook.com/help/566994660333381" target="_blank"
                                        rel="noopener noreferrer">https://de-de.facebook.com/help/566994660333381</a> und <a
                                        href="https://www.facebook.com/policy.php" target="_blank"
                                        rel="noopener noreferrer">https://www.facebook.com/policy.php</a>.</p>
                                    <h4>Twitter</h4> <p>Auf dieser Website sind Funktionen des Dienstes Twitter
                                    eingebunden. Diese Funktionen werden angeboten durch die Twitter International
                                    Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07, Irland.</p>
                                    <p>Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen
                                        Ihrem Endger&auml;t und dem Twitter-Server hergestellt. Twitter erh&auml;lt
                                        dadurch Informationen &uuml;ber den Besuch dieser Website durch Sie. Durch das
                                        Benutzen von Twitter und der Funktion &bdquo;Re-Tweet&ldquo; werden die von
                                        Ihnen besuchten Websites mit Ihrem Twitter-Account verkn&uuml;pft und anderen
                                        Nutzern bekannt gegeben. Wir weisen darauf hin, dass wir als Anbieter der Seiten
                                        keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch
                                        Twitter erhalten. Weitere Informationen hierzu finden Sie in der
                                        Datenschutzerkl&auml;rung von Twitter unter: <a
                                            href="https://twitter.com/de/privacy" target="_blank"
                                            rel="noopener noreferrer">https://twitter.com/de/privacy</a>.</p> <p>Soweit
                                    eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o.&nbsp;g.
                                    Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 TTDSG. Die
                                    Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung eingeholt wurde,
                                    erfolgt die Verwendung des Dienstes auf Grundlage unseres berechtigten Interesses an
                                    einer m&ouml;glichst umfassenden Sichtbarkeit in den Sozialen Medien.</p> <p>Die
                                    Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der
                                    EU-Kommission gest&uuml;tzt. Details finden Sie hier: <a
                                        href="https://gdpr.twitter.com/en/controller-to-controller-transfers.html"
                                        target="_blank"
                                        rel="noopener noreferrer">https://gdpr.twitter.com/en/controller-to-controller-transfers.html</a>.
                                </p> <p>Ihre Datenschutzeinstellungen bei Twitter k&ouml;nnen Sie in den
                                    Konto-Einstellungen unter <a href="https://twitter.com/account/settings"
                                                                 target="_blank"
                                                                 rel="noopener noreferrer">https://twitter.com/account/settings</a> &auml;ndern.
                                </p>
                                    <h4>Instagram</h4> <p>Auf dieser Website sind Funktionen des Dienstes Instagram
                                    eingebunden. Diese Funktionen werden angeboten durch die Meta Platforms Ireland
                                    Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland.</p> <p>Wenn
                                    das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem
                                    Endger&auml;t und dem Instagram-Server hergestellt. Instagram erh&auml;lt dadurch
                                    Informationen &uuml;ber den Besuch dieser Website durch Sie.</p> <p>Wenn Sie in
                                    Ihrem Instagram-Account eingeloggt sind, k&ouml;nnen Sie durch Anklicken des
                                    Instagram-Buttons die Inhalte dieser Website mit Ihrem Instagram-Profil verlinken.
                                    Dadurch kann Instagram den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir
                                    weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
                                    der &uuml;bermittelten Daten sowie deren Nutzung durch Instagram erhalten.</p>
                                    <p>Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des
                                        o.&nbsp;g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25
                                        TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung
                                        eingeholt wurde, erfolgt die Verwendung des Dienstes auf Grundlage unseres
                                        berechtigten Interesses an einer m&ouml;glichst umfassenden Sichtbarkeit in den
                                        Sozialen Medien.</p> <p>Soweit mit Hilfe des hier beschriebenen Tools
                                    personenbezogene Daten auf unserer Website erfasst und an Facebook bzw. Instagram
                                    weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand
                                    Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam f&uuml;r diese
                                    Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit
                                    beschr&auml;nkt sich dabei ausschlie&szlig;lich auf die Erfassung der Daten und
                                    deren Weitergabe an Facebook bzw. Instagram. Die nach der Weiterleitung erfolgende
                                    Verarbeitung durch Facebook bzw. Instagram ist nicht Teil der gemeinsamen
                                    Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer
                                    Vereinbarung &uuml;ber gemeinsame Verarbeitung festgehalten. Den Wortlaut der
                                    Vereinbarung finden Sie unter: <a
                                        href="https://www.facebook.com/legal/controller_addendum" target="_blank"
                                        rel="noopener noreferrer">https://www.facebook.com/legal/controller_addendum</a>.
                                    Laut dieser Vereinbarung sind wir f&uuml;r die Erteilung der
                                    Datenschutzinformationen beim Einsatz des Facebook- bzw. Instagram-Tools und
                                    f&uuml;r die datenschutzrechtlich sichere Implementierung des Tools auf unserer
                                    Website verantwortlich. F&uuml;r die Datensicherheit der Facebook bzw.
                                    Instagram-Produkte ist Facebook verantwortlich. Betroffenenrechte (z.&nbsp;B.
                                    Auskunftsersuchen) hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten
                                    k&ouml;nnen Sie direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte
                                    bei uns geltend machen, sind wir verpflichtet, diese an Facebook weiterzuleiten.</p>
                                    <p>Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der
                                        EU-Kommission gest&uuml;tzt. Details finden Sie hier: <a
                                            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                                            target="_blank"
                                            rel="noopener noreferrer">https://www.facebook.com/legal/EU_data_transfer_addendum</a>, <a
                                            href="https://help.instagram.com/519522125107875" target="_blank"
                                            rel="noopener noreferrer">https://help.instagram.com/519522125107875</a> und <a
                                            href="https://de-de.facebook.com/help/566994660333381" target="_blank"
                                            rel="noopener noreferrer">https://de-de.facebook.com/help/566994660333381</a>.
                                    </p> <p>Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von
                                    Instagram: <a href="https://instagram.com/about/legal/privacy/" target="_blank"
                                                  rel="noopener noreferrer">https://instagram.com/about/legal/privacy/</a>.
                                </p>
                                    <h4>LinkedIn</h4> <p>Diese Website nutzt Elemente des Netzwerks LinkedIn. Anbieter
                                    ist die LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2,
                                    Irland.</p> <p>Bei jedem Abruf einer Seite dieser Website, die Elemente von LinkedIn
                                    enth&auml;lt, wird eine Verbindung zu Servern von LinkedIn aufgebaut. LinkedIn wird
                                    dar&uuml;ber informiert, dass Sie diese Website mit Ihrer IP-Adresse besucht haben.
                                    Wenn Sie den &bdquo;Recommend-Button&ldquo; von LinkedIn anklicken und in Ihrem
                                    Account bei LinkedIn eingeloggt sind, ist es LinkedIn m&ouml;glich, Ihren Besuch auf
                                    dieser Website Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir weisen darauf hin, dass
                                    wir als Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten
                                    sowie deren Nutzung durch LinkedIn haben.</p> <p>Soweit eine Einwilligung (Consent)
                                    eingeholt wurde, erfolgt der Einsatz des o.&nbsp;g. Dienstes auf Grundlage von Art.
                                    6 Abs. 1 lit. a DSGVO und &sect; 25 TTDSG. Die Einwilligung ist jederzeit
                                    widerrufbar. Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung des
                                    Dienstes auf Grundlage unseres berechtigten Interesses an einer m&ouml;glichst
                                    umfassenden Sichtbarkeit in den Sozialen Medien.</p> <p>Die Daten&uuml;bertragung in
                                    die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                                    Details finden Sie hier: <a
                                        href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de"
                                        target="_blank"
                                        rel="noopener noreferrer">https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de</a>
                                </p> <p>Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von
                                    LinkedIn unter: <a href="https://www.linkedin.com/legal/privacy-policy"
                                                       target="_blank"
                                                       rel="noopener noreferrer">https://www.linkedin.com/legal/privacy-policy</a>.
                                </p>
                                    <h3>6. Analyse-Tools und Werbung</h3>
                                    <h4>Google Analytics</h4> <p>Diese Website nutzt Funktionen des Webanalysedienstes
                                    Google Analytics. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;),
                                    Gordon House, Barrow Street, Dublin 4, Irland.</p> <p>Google Analytics
                                    erm&ouml;glicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu
                                    analysieren. Hierbei erh&auml;lt der Websitebetreiber verschiedene Nutzungsdaten,
                                    wie z.&nbsp;B. Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und Herkunft
                                    des Nutzers. Diese Daten werden in einer User-ID zusammengefasst und dem jeweiligen
                                    Endger&auml;t des Websitebesuchers zugeordnet.</p> <p>Des Weiteren k&ouml;nnen wir
                                    mit Google Analytics u.&nbsp;a. Ihre Maus- und Scrollbewegungen und Klicks
                                    aufzeichnen. Ferner verwendet Google Analytics verschiedene
                                    Modellierungsans&auml;tze, um die erfassten Datens&auml;tze zu erg&auml;nzen und
                                    setzt Machine-Learning-Technologien bei der Datenanalyse ein.</p>
                                    <p>Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers zum
                                        Zwecke der Analyse des Nutzerverhaltens erm&ouml;glichen (z.&nbsp;B. Cookies
                                        oder Device-Fingerprinting). Die von Google erfassten Informationen &uuml;ber
                                        die Benutzung dieser Website werden in der Regel an einen Server von Google in
                                        den USA &uuml;bertragen und dort gespeichert.</p> <p>Die Nutzung dieses Dienstes
                                    erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO
                                    und &sect; 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.</p> <p>Die
                                    Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der
                                    EU-Kommission gest&uuml;tzt. Details finden Sie hier: <a
                                        href="https://privacy.google.com/businesses/controllerterms/mccs/"
                                        target="_blank"
                                        rel="noopener noreferrer">https://privacy.google.com/businesses/controllerterms/mccs/</a>.
                                </p>  <h5>Browser Plugin</h5> <p>Sie k&ouml;nnen die Erfassung und Verarbeitung Ihrer
                                    Daten durch Google verhindern, indem Sie das unter dem folgenden Link
                                    verf&uuml;gbare Browser-Plugin herunterladen und installieren: <a
                                        href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                                        rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>.
                                </p> <p>Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in
                                    der Datenschutzerkl&auml;rung von Google: <a
                                        href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank"
                                        rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.
                                </p><h5>Auftragsverarbeitung</h5> <p>Wir haben mit Google einen Vertrag zur
                                    Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen
                                    Datenschutzbeh&ouml;rden bei der Nutzung von Google Analytics vollst&auml;ndig
                                    um.</p>
                                    <h3>7. Plugins und Tools</h3>
                                    <h4>Google Fonts (lokales Hosting)</h4> <p>Diese Seite nutzt zur einheitlichen
                                    Darstellung von Schriftarten so genannte Google Fonts, die von Google bereitgestellt
                                    werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von
                                    Google findet dabei nicht statt.</p> <p>Weitere Informationen zu Google Fonts finden
                                    Sie unter <a href="https://developers.google.com/fonts/faq" target="_blank"
                                                 rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> und
                                    in der Datenschutzerkl&auml;rung von Google: <a
                                        href="https://policies.google.com/privacy?hl=de" target="_blank"
                                        rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
                                    <h4>Adobe Fonts</h4> <p>Diese Website nutzt zur einheitlichen Darstellung bestimmter
                                    Schriftarten Web Fonts von Adobe. Anbieter ist die Adobe Systems Incorporated, 345
                                    Park Avenue, San Jose, CA 95110-2704, USA (Adobe).</p> <p>Beim Aufruf dieser Website
                                    l&auml;dt Ihr Browser die ben&ouml;tigten Schriftarten direkt von Adobe, um sie
                                    Ihrem Endger&auml;t korrekt anzeigen zu k&ouml;nnen. Dabei stellt Ihr Browser eine
                                    Verbindung zu den Servern von Adobe in den USA her. Hierdurch erlangt Adobe Kenntnis
                                    dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen wurde. Bei der
                                    Bereitstellung der Schriftarten werden nach Aussage von Adobe keine Cookies
                                    gespeichert.</p> <p>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                                    Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
                                    der einheitlichen Darstellung des Schriftbildes auf seiner Website. Sofern eine
                                    entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                                    ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                                    1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
                                    Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im
                                    Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p> <p>Die
                                    Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der
                                    EU-Kommission gest&uuml;tzt. Details finden Sie hier: <a
                                        href="https://www.adobe.com/de/privacy/eudatatransfers.html" target="_blank"
                                        rel="noopener noreferrer">https://www.adobe.com/de/privacy/eudatatransfers.html</a>.
                                </p> <p>N&auml;here Informationen zu Adobe Fonts erhalten Sie unter: <a
                                    href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html" target="_blank"
                                    rel="noopener noreferrer">https://www.adobe.com/de/privacy/policies/adobe-fonts.html</a>.
                                </p> <p>Die Datenschutzerkl&auml;rung von Adobe finden Sie unter: <a
                                    href="https://www.adobe.com/de/privacy/policy.html" target="_blank"
                                    rel="noopener noreferrer">https://www.adobe.com/de/privacy/policy.html</a></p>
                                    <h4>Font Awesome (lokales Hosting)</h4> <p>Diese Seite nutzt zur einheitlichen
                                    Darstellung von Schriftarten Font Awesome. Font Awesome ist lokal installiert. Eine
                                    Verbindung zu Servern von Fonticons, Inc. findet dabei nicht statt.</p> <p>Weitere
                                    Informationen zu Font Awesome finden Sie in der Datenschutzerkl&auml;rung f&uuml;r
                                    Font Awesome unter: <a href="https://fontawesome.com/privacy" target="_blank"
                                                           rel="noopener noreferrer">https://fontawesome.com/privacy</a>.
                                </p>
                                    <h4>Google Maps</h4> <p>Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist
                                    die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street,
                                    Dublin 4, Irland.</p> <p>Zur Nutzung der Funktionen von Google Maps ist es
                                    notwendig, Ihre IP-Adresse zu speichern. Diese Informationen werden in der Regel an
                                    einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Der
                                    Anbieter dieser Seite hat keinen Einfluss auf diese Daten&uuml;bertragung. Wenn
                                    Google Maps aktiviert ist, kann Google zum Zwecke der einheitlichen Darstellung der
                                    Schriftarten Google Fonts verwenden. Beim Aufruf von Google Maps l&auml;dt Ihr
                                    Browser die ben&ouml;tigten Web Fonts in ihren Browsercache, um Texte und
                                    Schriftarten korrekt anzuzeigen.</p> <p>Die Nutzung von Google Maps erfolgt im
                                    Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer
                                    leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt
                                    ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
                                    entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                                    ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                                    1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
                                    Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im
                                    Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p> <p>Die
                                    Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der
                                    EU-Kommission gest&uuml;tzt. Details finden Sie hier: <a
                                        href="https://privacy.google.com/businesses/gdprcontrollerterms/"
                                        target="_blank"
                                        rel="noopener noreferrer">https://privacy.google.com/businesses/gdprcontrollerterms/</a> und <a
                                        href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
                                        target="_blank"
                                        rel="noopener noreferrer">https://privacy.google.com/businesses/gdprcontrollerterms/sccs/</a>.
                                </p> <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
                                    Datenschutzerkl&auml;rung von Google: <a
                                        href="https://policies.google.com/privacy?hl=de" target="_blank"
                                        rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
                                    <h4>Google reCAPTCHA</h4> <p>Wir nutzen &bdquo;Google reCAPTCHA&ldquo; (im
                                    Folgenden &bdquo;reCAPTCHA&ldquo;) auf dieser Website. Anbieter ist die Google
                                    Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4,
                                    Irland.</p> <p>Mit reCAPTCHA soll &uuml;berpr&uuml;ft werden, ob die Dateneingabe
                                    auf dieser Website (z.&nbsp;B. in einem Kontaktformular) durch einen Menschen oder
                                    durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das
                                    Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt
                                    automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet
                                    reCAPTCHA verschiedene Informationen aus (z.&nbsp;B. IP-Adresse, Verweildauer des
                                    Websitebesuchers auf der Website oder vom Nutzer get&auml;tigte Mausbewegungen). Die
                                    bei der Analyse erfassten Daten werden an Google weitergeleitet.</p> <p>Die
                                    reCAPTCHA-Analysen laufen vollst&auml;ndig im Hintergrund. Websitebesucher werden
                                    nicht darauf hingewiesen, dass eine Analyse stattfindet.</p> <p>Die Speicherung und
                                    Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                                    Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor
                                    missbr&auml;uchlicher automatisierter Aussp&auml;hung und vor SPAM zu sch&uuml;tzen.
                                    Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                                    ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                                    1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
                                    Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im
                                    Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p> <p>Weitere
                                    Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen
                                    und den Google Nutzungsbedingungen unter folgenden Links: <a
                                        href="https://policies.google.com/privacy?hl=de" target="_blank"
                                        rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a> und <a
                                        href="https://policies.google.com/terms?hl=de" target="_blank"
                                        rel="noopener noreferrer">https://policies.google.com/terms?hl=de</a>.</p>
                                    <h3>8. Online-Marketing und Partner&shy;programme</h3>
                                    <h4>Affiliate-Programme auf dieser Website</h4> <p>Wir nehmen an
                                    Affiliate-Partnerprogrammen teil. Bei Affiliate-Partner-Programmen werden
                                    Werbeanzeigen eines Unternehmens (Advertiser) auf Webseiten von anderen Unternehmen
                                    des Affiliate-Partner-Netzwerks (Publisher) platziert. Wenn Sie auf eine dieser
                                    Affiliate-Werbeanzeigen klicken, werden Sie zum beworbenen Angebot weitergeleitet.
                                    Sollten Sie anschlie&szlig;end eine bestimmte Transaktion (Conversion) t&auml;tigen,
                                    erh&auml;lt der Publisher hierf&uuml;r eine Verg&uuml;tung. Zur Berechnung dieser
                                    Verg&uuml;tung ist es erforderlich, dass der Affiliate-Netzwerkbetreiber
                                    nachvollziehen kann, &uuml;ber welche Werbeanzeige Sie auf das jeweilige Angebot
                                    gekommen sind und die vordefinierte Transaktion vorgenommen haben. Hierf&uuml;r
                                    werden Cookies oder vergleichbare Wiedererkennungstechnologien (z.&nbsp;B.
                                    Device-Fingerprinting) eingesetzt.</p> <p>Die Speicherung und Analyse der Daten
                                    erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                                    berechtigtes Interesse an der korrekten Berechnung seiner Affiliate-Verg&uuml;tung.
                                    Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                                    ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                                    1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
                                    Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im
                                    Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
                                    <p>Wir nehmen an folgenden Affiliate-Programmen teil:</p>
                                    <h5>eRecht24 Affiliate-Programm</h5> <p>Betreiber des Affiliate-Netzwerks ist die
                                    eRecht24 GmbH &amp; Co KG, Lietzenburger Str. 94, 10719 Berlin. Wenn Sie auf einen
                                    eRecht24-Affiliate-Link klicken, werden Sie zun&auml;chst an den
                                    Zahlungsdienstleister Digistore24 (Digistore24 GmbH, St.-Godehard-Stra&szlig;e 32 in
                                    31139 Hildesheim) weitergeleitet, der mithilfe einer Wiedererkennungstechnologie
                                    (z.&nbsp;B. Cookie) vermerkt, dass Sie &uuml;ber unsere Website zu den
                                    eRecht24-Angeboten gelangt sind; dies geschieht zum Zwecke der Abrechnung der
                                    Affiliate-Provision.</p>
                                    <h3>9. eCommerce und Zahlungs&shy;anbieter</h3>
                                    <h4>Verarbeiten von Kunden- und Vertragsdaten</h4> <p>Wir erheben, verarbeiten und
                                    nutzen personenbezogene Kunden- und Vertragsdaten zur Begr&uuml;ndung, inhaltlichen
                                    Ausgestaltung und &Auml;nderung unserer Vertragsbeziehungen. Personenbezogene
                                    Daten &uuml;ber die Inanspruchnahme dieser Website (Nutzungsdaten) erheben,
                                    verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die
                                    Inanspruchnahme des Dienstes zu erm&ouml;glichen oder abzurechnen. Rechtsgrundlage
                                    hierf&uuml;r ist Art. 6 Abs. 1 lit. b DSGVO.</p> <p>Die erhobenen Kundendaten werden
                                    nach Abschluss des Auftrags oder Beendigung der Gesch&auml;ftsbeziehung und Ablauf
                                    der ggf. bestehenden gesetzlichen Aufbewahrungsfristen gel&ouml;scht. Gesetzliche
                                    Aufbewahrungsfristen bleiben unber&uuml;hrt.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default PrivacyPolicy;
