import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Kollaborativer Ansatz",
        para: "Wir schätzen die Zusammenarbeit und glauben, dass die besten Ergebnisse aus der engen Zusammenarbeit mit unseren Kunden entstehen. Wir hören aufmerksam auf Ihre Ideen, Ziele und Herausforderungen und berücksichtigen Ihre wertvollen Beiträge während des gesamten Entwicklungsprozesses. Ihre Vision steht im Mittelpunkt all unserer Aktivitäten."
    },
    {
        id: 2,
        title: "Technische Fachkenntnisse",
        para: "Mit jahrelanger Branchenerfahrung besitzt unser Team die technische Fachkenntnis, um Ihre Ideen in robuste, skalierbare Lösungen zu transformieren. Von Webentwicklung und E-Commerce-Lösungen bis hin zu Design und Branding - wir haben die Fähigkeiten und das Wissen, um vielfältige Projekte in verschiedenen Branchen zu handhaben."
    },
    {
        id: 3,
        title: "Innovation und Kreativität",
        para: "Wir gedeihen auf Innovation und umarmen Kreativität in allen Aspekten unserer Arbeit. Wir bringen frische Ideen auf den Tisch und finden erfinderische Lösungen für komplexe Herausforderungen. Unser Ziel ist es, einzigartige und wirkungsvolle Lösungen zu liefern, die Sie von Ihren Mitbewerbern abheben und einen bleibenden Eindruck bei Ihrem Publikum hinterlassen."
    },
    {
        id: 4,
        title: "Liebe zum Detail",
        para: "Wir legen bei jedem Projekt, das wir übernehmen, penibel Wert auf Details. Von der ersten Planungsphase bis zur endgültigen Umsetzung stellen wir sicher, dass jedes Element sorgfältig gestaltet und akribisch ausgeführt wird. Unser Engagement für Exzellenz garantiert, dass Ihr Projekt den höchsten Qualitätsstandards entspricht."
    }
]

const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle
                    subtitle="Unsere Werte"
                    title="Warum sollten Sie mit uns zusammenarbeiten?"
                    description="Ihre Vision, unsere Expertise: Zusammenarbeiten für unvergleichliche Lösungen"
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;
