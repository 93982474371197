import React from 'react';
import {Link} from 'react-router-dom';
import {
    FaFacebookF,
    FaTwitter,
    FaPinterestP,
    FaLinkedin,
    FaInstagram,
    FaVimeoV,
    FaDribbble,
    FaBehance,
    FaEnvelopeOpen
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import {slugify} from '../../utils';
import Logo from "../../elements/logo/Logo";

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {

    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    {/*<div className="footer-social-link">*/}
                    {/*    <ul className="list-unstyled">*/}
                    {/*        <li><Link to="https://facebook.com/"><FaFacebookF /></Link></li>*/}
                    {/*        <li><Link to="https://twitter.com/"><FaTwitter /></Link></li>*/}
                    {/*        <li><Link to="https://www.linkedin.com/"><FaLinkedin /></Link></li>*/}
                    {/*        <li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-5 col-lg-4">
                            <div className="footer-widget border-end">
                                <div className="header-logo">
                                    <img className="light-version-logo"
                                         src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo"/>
                                </div>
                                <div className="text-gray-400 mt-2">
                                    Lösungen erstellen, #ErfolgAntreiben
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-8">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Dienstleistungen</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        <Link
                                                            to={process.env.PUBLIC_URL + `/${data.slug}`}>{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Kontaktieren Sie uns</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={'#'}>info@regulus-solutions.de</Link></li>
                                                <li><Link to={'#'}>+49 7071 639 8294</Link></li>
                                                <li><Link to={'#'}>Bruckenäcker 6, Kusterdingen</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Unternehmen</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/impressum"}>Impressum</Link>
                                                </li>
                                                <li><Link
                                                    to={process.env.PUBLIC_URL + "/privacy-policy"}>Datenschutzrichtlinie</Link>
                                                </li>
                                                <li><Link to={''}
                                                       onClick={() => window.UC_UI.showSecondLayer()}>Cookie-Einstellungen</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="footer-copyright">
                                <span className="copyright-text">© Regulus Solutions {new Date().getFullYear()}. Alle Rechte vorbehalten.</span>
                            </div>
                        </div>
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="footer-bottom-link">*/}
                        {/*        <ul className="list-unstyled">*/}
                        {/*            <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>*/}
                        {/*            <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;
