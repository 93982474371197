import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import {FaCompress, FaCode, FaGlobe} from 'react-icons/fa';


const WhyChooseOne = () => {
    return (
        <div className="section section-padding bg-color-light">
            <div className="container">
                <div className="why-choose-us pt-4">
                    <SectionTitle
                        subtitle="Lösungen erstellen, #ErfolgAntreiben"
                        title="Ihre Ideen umsetzen"
                        description="Wir sind leidenschaftlich daran interessiert, Ihre Ideen in die Realität umzusetzen. Wir glauben, dass jedes großartige Unternehmen mit einem Funken Inspiration beginnt, und wir sind hier, um Ihnen zu helfen, diese Vision zum Leben zu erwecken. Unser Team von qualifizierten Fachleuten ist darauf spezialisiert, innovative Lösungen zu erstellen, die mit Ihren Zielen übereinstimmen und Ihren Erfolg vorantreiben."
                        textAlignment="heading"
                        textColor=""
                    />
                </div>
            </div>
            <ul className="shape-group-7 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                                                   alt="circle"/></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                                                   alt="Line"/></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                                                   alt="Line"/></li>
            </ul>
        </div>

    )
}

export default WhyChooseOne;
